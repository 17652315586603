import "./App.css";
import Hero from "./Hero.js";
import Description from "./sections/Description.js";
import LearningPaths from "./sections/KeyFeatures/LearningPaths.js";
import Footer from "./sections/Footer.js";
import InstrumentPicker from "./sections/KeyFeatures/InstrumentPicker.js";
import Minigames from "./sections/KeyFeatures/Minigames.js";
import MoreFeatures from "./sections/KeyFeatures/MoreFeatures.js";
import NavBar from "./NavBar.js";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { SiLangchain } from "react-icons/si";
import LegalPage from "./Legal/Legal.js";

function LandingPage() {
  return (
    <div className="App">
      <NavBar />
      <Hero />
      <div className="content">
        <Description />
        <InstrumentPicker />
        <Minigames />
        <LearningPaths />
        <MoreFeatures />
      </div>
      <Footer />
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/legal" element={<LegalPage />} />
      </Routes>
    </Router>
  );
}

export default App;
