import { React, useState } from "react";
import { motion, useScroll, useMotionValueEvent } from "framer-motion";
import "./NavBar.css";

const linkList = ["", "", "", "", ""];

export default function NavBar() {
  const { scrollY } = useScroll();
  const [hidden, setHidden] = useState(true);
  const [prevScroll, setPrevScroll] = useState(0);

  function update(latest, prev) {
    if (latest < prev && latest > 500) {
      setHidden(false);
    } else if ((latest > 100 && latest > prev) || latest < 500) {
      setHidden(true);
    }
  }

  useMotionValueEvent(scrollY, "change", (latest) => {
    update(latest, prevScroll);
    setPrevScroll(latest);
  });

  return <motion.nav></motion.nav>;
}
