import { React, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./InstrumentPicker.css";
import "./KeyFeatures.css";

const pickerVariants = {
  normal: { scale: 1, zIndex: 0, opacity: 0.6 },
  hover: { scale: 1.1, zIndex: 2, opacity: 1 },
  selected: { scale: 1.1, zIndex: 1, opacity: 1, border: "3px solid black" },
};

const instruments = {
  piano: "piano.png",
  guitar: "guitar.png",
  bass: "bass.png",
  ukulele: "ukulele.png",
  ladder: "ladder.png",
};

const appPreviews = {
  guitar: "guitar.jpeg",
  piano: "piano.PNG",
  bass: "bass.PNG",
  ukulele: "ukulele.PNG",
  ladder: "ladder.PNG",
};

const variants = {
  enter: { opacity: 1 },
  center: { zIndex: 1 },
  exit: { zIndex: 0, opacity: 1 },
};

export default function InstrumentPicker() {
  const [selectedInstrument, setSelectedInstrument] = useState("guitar");

  return (
    <div className="instruments-preview key-feature-section">
      <div className="text">
        <h1>Choose your instrument.</h1>
        <p>
          Do ear training on your instrument of choice: guitar, piano, bass and
          ukulele. <br /> ...and our very own creation: the ladder! Just to show
          how simple these ideas really are.
        </p>
      </div>

      <div className="instrument-picker">
        {Object.keys(instruments).map((key) => (
          <motion.button
            key={key}
            className="picker-button instrument-picker-button"
            animate={selectedInstrument === key ? "selected" : "normal"}
            whileHover={selectedInstrument === key ? "selected" : "hover"}
            variants={pickerVariants}
            onClick={() => setSelectedInstrument(key)}
          >
            <img
              className="icon"
              src={"app-screenshots/icons/" + instruments[key]}
              alt={key}
            />
          </motion.button>
        ))}
      </div>

      <div className="app-preview-box">
        <img
          className="app-preview-background"
          src="app-screenshots/previews/guitar.jpeg"
          alt="size-fixer"
        />

        <AnimatePresence>
          <motion.img
            key={selectedInstrument}
            className="app-preview"
            src={"app-screenshots/previews/" + appPreviews[selectedInstrument]}
          />
        </AnimatePresence>
      </div>
    </div>
  );
}
