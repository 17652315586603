import { React, useEffect, useState } from "react";
import "./Hero.css";
import { motion, useAnimate } from "framer-motion";

export default function Hero() {
  const [scope, animate] = useAnimate();

  const variants = {
    initial: { opacity: 0 },
    showing: { opacity: 1 },
  };

  useEffect(() => {
    animate([
      ["#hear-it", { opacity: 1 }],
      ["#read-it-box", { opacity: 1 }, { delay: 0.3 }],
      ["#play-it", { opacity: 1 }, { delay: 0.3 }],
      ["#left-line", { pathLength: 1 }, { duration: 0.1, delay: 0.4 }],
      ["#right-line", { pathLength: 1 }, { duration: 0.1 }],
      ["#read-it-box", { opacity: 0 }, { delay: 0.3 }],
      ["#read-it-box", { width: "0px", height: "0px" }, { duration: 0.3 }],
      [
        "#no-sheet",
        { opacity: 1, scale: 1 },
        { type: "spring", stiffness: 100, damping: 11, delay: -0.15 },
      ],
      [".nosheet-logo", { opacity: 1, y: 0 }, { delay: -0.4, duration: 0.5 }],
      [".download-link", { opacity: 1 }, { delay: 0.2 }],
    ]);
  });

  return (
    <div ref={scope} className="hero">
      <div className="hero-text">
        <motion.h1 initial="initial" variants={variants} id="hear-it">
          Hear it.
        </motion.h1>
        <motion.div initial="initial" variants={variants} id="read-it-box">
          <h1 id="read-it">Read it.</h1>
          <motion.svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            id="squiggle"
          >
            <motion.line
              x1="10"
              y1="90"
              x2="90"
              y2="10"
              stroke="red"
              stroke-width="10"
              strokeLinecap="butt"
              id="left-line"
              initial={{ pathLength: 0 }}
            />
            <motion.line
              x1="10"
              y1="10"
              x2="90"
              y2="90"
              stroke="red"
              stroke-width="10"
              strokeLinecap="butt"
              id="right-line"
              initial={{ pathLength: 0 }}
            />
          </motion.svg>
        </motion.div>
        <motion.h1 initial="initial" variants={variants} id="play-it">
          Play it.
        </motion.h1>
      </div>

      <motion.h1 id="no-sheet" initial={{ opacity: 0, scale: 4 }}>
        NoSheet.
      </motion.h1>

      <motion.img
        className="nosheet-logo"
        initial={{ y: "30px", opacity: 0 }}
        src="/waving-in-the-breeze.gif"
        alt="no-sheet-logo"
      />

      <motion.a
        className="download-link"
        initial="initial"
        variants={variants}
        href="https://apps.apple.com/de/app/nosheet-ear-trainer/id1666553594"
      >
        <img src="/appstore-download.svg" />
      </motion.a>
    </div>
  );
}

/*

export default function Hero() {
  const [scope, animate] = useAnimate();
  const [showHear, setShowHear] = useState(false);
  const [showRead, setShowRead] = useState(false);
  const [showPlay, setShowPlay] = useState(false);
  const [showNoSheet, setShowNoSheet] = useState(false);

  const animInterval = 600;

  const transition = {
    opacity: { duration: 0.3 },
    layout: { duration: 0.3 },
  };

  const variants = {
    initial: { opacity: 0 },
    showing: { opacity: 1 },
  };

  useEffect(() => {
    setTimeout(() => {
      setShowHear(true);
    }, animInterval);

    setTimeout(() => {
      setShowRead(true);
    }, animInterval * 2);

    setTimeout(() => {
      setShowPlay(true);
    }, animInterval * 3);

    setTimeout(() => {
      setShowHear(false);
      setShowRead(false);
      setShowPlay(false);
      setShowNoSheet(true);
    }, animInterval * 4);
  }, []);

  return (
    <div ref={scope} className="hero">
      <div className="hero-text">
        <LayoutGroup>
          {showHear && (
            <motion.h1
              layout
              initial="initial"
              animate="showing"
              variants={variants}
              transition={transition}
              id="hear-it"
            >
              Hear it.
            </motion.h1>
          )}
          {showRead && (
            <motion.h1
              layout
              initial="initial"
              animate="showing"
              variants={variants}
              transition={transition}
              id="read-it"
            >
              Read it.
            </motion.h1>
          )}
          {showPlay && (
            <motion.h1
              layout
              initial="initial"
              animate="showing"
              variants={variants}
              transition={transition}
              id="play-it"
            >
              Play it.
            </motion.h1>
          )}
        </LayoutGroup>
      </div>

      {showNoSheet && (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <motion.h1
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            id="no-sheet"
          >
            NoSheet.
          </motion.h1>

          <motion.img
            className="nosheet-logo"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            src="/waving-in-the-breeze.gif"
            alt="no-sheet-logo"
          />

          <motion.a
            className="download-link"
            href="https://apps.apple.com/de/app/nosheet-ear-trainer/id1666553594"
          >
            <img src="/appstore-download.svg" />
          </motion.a>
        </motion.div>
      )}
    </div>
  );
}

*/
