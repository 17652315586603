import { React, useState } from "react";
import { motion, AnimatePresence, color } from "framer-motion";
import "./Minigames.css";
import "./KeyFeatures.css";
import { pickerVariants } from "./PickerVariants";

const minigames = [
  {
    name: "the coarse game",
    iconPath: "coarse.png",
    preview: "coarse.gif",
    description: "lalalalalalala",
  },
  {
    name: "the fine game",
    iconPath: "fine.png",
    preview: "fine.gif",
    description: "lalalalalalala",
  },
  {
    name: "the degree game",
    iconPath: "degree.png",
    preview: "degree.gif",
    description: "lalalalalalala",
  },
  {
    name: "the member game",
    iconPath: "member.png",
    preview: "member.gif",
    description: "lalalalalalala",
  },
  {
    name: "the song game",
    iconPath: "song.png",
    preview: "song.gif",
    description: "lalalalalalala",
  },
  {
    name: "the audiation game",
    iconPath: "audiation.png",
    preview: "audiation.gif",
    description: "lalalalalalala",
  },
];

const accentColors = ["red", "blue", "green", "yellow", "pink", "brown"];

export default function Minigames() {
  const [selectedGame, setSelectedGame] = useState(-1);

  return (
    <div className="minigames key-feature-section">
      <div className="text">
        <h1>6 Minigames</h1>
        <p>
          Grounded in everything that music theory and ear training have to
          offer, to get you playing and improvising as quickly as possible.
          Fully configurable, so beginner or expert, you will find the right
          challenge.
        </p>
      </div>

      <motion.div className="minigame-previews-container">
        {minigames.map((item, index) => (
          <motion.div
            className="minigame-preview"
            onHoverStart={() => setSelectedGame(index)}
            onHoverEnd={() => setSelectedGame(-1)}
            onTap={() => {
              index === selectedGame
                ? setSelectedGame(-1)
                : setSelectedGame(index);
            }}
            whileHover={{
              zIndex: 10,
              scale: 1.1,
              border: "3px solid " + accentColors[index],
            }}
          >
            <motion.img
              className="icon"
              src={"minigames/icons/" + item.iconPath}
              animate={{
                y: index === selectedGame ? "-100%" : "0px",
              }}
              alt={item.name}
            />

            <motion.h2 animate={{ y: index === selectedGame ? "70px" : "0px" }}>
              {item.name}
            </motion.h2>

            <motion.div
              className="hover-preview"
              animate={{
                opacity: index === selectedGame ? 1 : 0,
              }}
            >
              <motion.img
                layoutId="preview-img"
                key={selectedGame.name}
                className="minigame-preview-video"
                src={"minigames/minigames_iphone_gifs/" + item.preview}
              />

              <p className="minigame-description">{item.description}</p>
            </motion.div>
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
}
