import React from "react";
import { motion } from "framer-motion";
import "./KeyFeatures.css";
import "./MoreFeatures.css";
import { BsLightningCharge } from "react-icons/bs";
import { GiMusicalScore } from "react-icons/gi";
import { FaCogs } from "react-icons/fa";
import { TbGuitarPickFilled } from "react-icons/tb";

const features = [
  {
    title: "Music theory, built in",
    icon: <GiMusicalScore size={70} />,
    description: "You'll be learning it without even knowing it.",
  },
  {
    title: "Instant feedback",
    icon: <BsLightningCharge size={70} />,
    description:
      "Hear it, then play it... and immediately know how well you did!",
  },
  {
    title: "Fully configurable",
    icon: <FaCogs size={70} />,
    description:
      "Every minigame can be configured to your taste, beginner or advanced.",
  },
  {
    title: "Fretboard freedom",
    icon: <TbGuitarPickFilled size={70} />,
    description: "The fretboard can be daunting. Not with NoSheet!",
  },
];

export default function MoreFeatures() {
  return (
    <div className="more-features">
      {features.map((feature) => (
        <motion.div
          className="feature-card"
          whileHover={{
            transform: "rotate(3deg)",
          }}
        >
          <h2>{feature.title}</h2>
          {feature.icon}
          <p>{feature.description}</p>
        </motion.div>
      ))}
    </div>
  );
}
