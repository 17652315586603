import React, { useRef, useEffect } from "react";
import { motion } from "framer-motion";

const AutoplayVideoOnce = ({ src }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      videoElement.play().catch((error) => {
        console.error("Autoplay was prevented:", error);
      });
    }

    return () => {
      if (videoElement) {
        videoElement.pause();
        videoElement.currentTime = 0;
      }
    };
  }, []);

  return (
    <motion.video
      style={{ maxWidth: "100%" }}
      ref={videoRef}
      onViewportEnter={() =>
        videoRef.current.play().catch((error) => {
          console.error("Autoplay was prevented:", error);
        })
      }
      muted
      playsInline
    >
      <source src={src} type="video/mp4" />
      Your browser does not support the video tag.
    </motion.video>
  );
};

export default AutoplayVideoOnce;
