import React from "react";
import "./Legal.css";
import Footer from "../sections/Footer.js";

export default function LegalPage() {
  return (
    <div className="legal-page">
      <div className="legal-content">
        <h2>Impressum</h2>
        <p>
          Nicholas Fest
          <br />
          Walhallastr. 4<br />
          80639 M&uuml;nchen
        </p>
        <h2>Kontakt</h2>
        <p>
          Telefon: +49 (0) 1627722440
          <br />
          E-Mail: nick@nosheet.app
        </p>
        <h2>Privacy Policy</h2>
        <p></p>
        <h2>Terms & Conditions</h2>
        <p></p>
      </div>

      <Footer />
    </div>
  );
}
