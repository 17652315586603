import React from "react";
import { motion } from "framer-motion";
import "../App.css";
import "./Description.css";

const descrVariants = {
  initial: {
    opacity: 0,
    y: "200px",
  },
  default: {
    opacity: 1,
    y: "0px",
  },
};

export default function Description() {
  return (
    <div className="description">
      <div className="text-and-image sheet-music-bad">
        <div className="text">
          <h2>You hear a song you like...</h2>
          <p>
            ... and you look up the sheet music, guitar tabs, piano roll...
            whatever! And then you work your way through, note by note. <br />
            <br /> It doesn't feel very musical, does it?
          </p>
        </div>
        <div className="image">
          <motion.img
            initial="initial"
            whileInView="default"
            variants={descrVariants}
            transition={{ delay: 0.3, type: "spring" }}
            viewport={{ once: true }}
            src="./bogged-down.png"
          />
        </div>
      </div>

      <div className="text-and-image nosheet-good">
        <div className="image">
          <motion.img
            initial="initial"
            whileInView="default"
            variants={descrVariants}
            transition={{ delay: 0.3, type: "spring" }}
            viewport={{ once: true }}
            src="./ear-trainer-freed.png"
          />
        </div>
        <div className="text">
          <p>
            What if you could go straight to your instrument? No notation in the
            middle, to slow you down? You hear a song, and you figure it out by
            ear, by playing around. Sound to sound, not sight to sound. <br />
            <br />
            Ear training is the answer! And NoSheet makes it fun.
          </p>
          <h2></h2>
        </div>
      </div>
    </div>
  );
}
