import React from "react";
import "./Footer.css";

export default function Footer() {
  return (
    <div className="footer">
      <div className="logo-and-download">
        <a href="/">
          <img className="logo" src="NoSheetLogo.png" alt="logo" />
        </a>
        <h2> NoSheet</h2>
        <a href="https://apps.apple.com/de/app/nosheet-ear-trainer/id1666553594">
          <img
            className="downloadlink"
            src="appstore-download.svg"
            alt="appstore download"
          />
        </a>
      </div>
      <div className="footer-content">
        <div className="contact">
          <p>
            NoSheet is an independent app. We're working hard on adding more
            features, and we would love to hear your thoughts and feedback in
            the meantime. Just shoot us an e-mail!
          </p>

          <a href="mailto: nick@nosheet.app">
            <h3>nick@nosheet.app</h3>
          </a>
        </div>

        <div className="legal">
          <a href="/legal">Impressum</a>
          <a href="/legal">Privacy Policy</a>
          <a href="/legal">Terms & Conditions</a>
          <p>© Nick Fest 2024</p>
        </div>
      </div>
    </div>
  );
}
