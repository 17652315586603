import React from "react";
import "./LearningPaths.css";
import "./KeyFeatures.css";
import AutoplayVideoOnce from "./AutoPlayVideoOnce";

export default function LearningPaths() {
  return (
    <div className="learning-paths key-feature-section">
      <div className="learning-paths-description">
        <h1>Interactive Learning Paths</h1>
        <p>
          You can just play the minigames. But we've also created learning paths
          for you, so you don't have to decide which minigame to play, when, in
          what configuration, for how long... Simple challenges, simple success
          metrics.
        </p>
      </div>

      <div className="learning-path-preview">
        <AutoplayVideoOnce src="paths.mov" width="500px" />
      </div>
    </div>
  );
}
